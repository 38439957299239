// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__two-img {
        display: grid;
            grid-template-columns: repeat(2, 1fr); 
            gap: 24px; 
            width: 100%; 
            row-gap: 0;
}

@media (max-width: 620px) {
.project__two-img {
    grid-template-columns: repeat(1, 1fr);
}
}

.project__two-img > :last-child {
    margin-right: 0; 
}





`, "",{"version":3,"sources":["webpack://./src/blocks/project/__two-img/project__two-img.css"],"names":[],"mappings":"AAAA;QACQ,aAAa;YACT,qCAAqC;YACrC,SAAS;YACT,WAAW;YACX,UAAU;AACtB;;AAEA;AACA;IACI,qCAAqC;AACzC;AACA;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".project__two-img {\n        display: grid;\n            grid-template-columns: repeat(2, 1fr); \n            gap: 24px; \n            width: 100%; \n            row-gap: 0;\n}\n\n@media (max-width: 620px) {\n.project__two-img {\n    grid-template-columns: repeat(1, 1fr);\n}\n}\n\n.project__two-img > :last-child {\n    margin-right: 0; \n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
