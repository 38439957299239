// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__stroke {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: flex-end;
}

@media (max-width: 620px) {
    .footer__stroke {
        flex-direction: column;
        align-items: flex-start;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__grid/footer__stroke.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;AACJ","sourcesContent":[".footer__stroke {\n    display: flex;\n    width: 100%;\n    position: relative;\n    z-index: 1;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n\n@media (max-width: 620px) {\n    .footer__stroke {\n        flex-direction: column;\n        align-items: flex-start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
