// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__gif {
    width: 84px;
    border-radius: 1000px;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .footer__gif {
width: 60px;
    }
}

@media (max-width: 620px) {
    .footer__gif {
display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__gif/footer__gif.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI;AACJ,WAAW;IACP;AACJ;;AAEA;IACI;AACJ,aAAa;IACT;AACJ","sourcesContent":[".footer__gif {\n    width: 84px;\n    border-radius: 1000px;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .footer__gif {\nwidth: 60px;\n    }\n}\n\n@media (max-width: 620px) {\n    .footer__gif {\ndisplay: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
