// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me {
    margin-bottom: 100px;
padding-top: 52px;
}

@media (max-width:620px) {
    .me {
        margin-bottom: 80px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/blocks/me/me.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB,iBAAiB;AACjB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".me {\n    margin-bottom: 100px;\npadding-top: 52px;\n}\n\n@media (max-width:620px) {\n    .me {\n        margin-bottom: 80px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
