// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__logo-p {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 550;
font-size: 32px;
line-height: 36px;
color: #050024;
margin: 0;
width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/blocks/header/__logo-p/header__logo-p.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,SAAS;AACT,kBAAkB;AAClB","sourcesContent":[".header__logo-p {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 550;\nfont-size: 32px;\nline-height: 36px;\ncolor: #050024;\nmargin: 0;\nwidth: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
