// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.footer__links:last-child {
    margin-right: 0;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .footer__links {
flex-direction: column;
    }
}

@media (max-width: 620px) {
    .footer__links {
        order: 3;
        justify-content: flex-start;
    }
} `, "",{"version":3,"sources":["webpack://./src/blocks/footer/__links/footer__links.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;AACJ,sBAAsB;IAClB;AACJ;;AAEA;IACI;QACI,QAAQ;QACR,2BAA2B;IAC/B;AACJ","sourcesContent":[".footer__links {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.footer__links:last-child {\n    margin-right: 0;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .footer__links {\nflex-direction: column;\n    }\n}\n\n@media (max-width: 620px) {\n    .footer__links {\n        order: 3;\n        justify-content: flex-start;\n    }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
