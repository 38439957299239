// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__title {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 44px;
text-align: right;
color: #8B8B8B;
margin: 0;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .me__title {
font-weight: 500;
font-size: 32px;
line-height: 36px;
    }
}

@media (max-width: 620px) {
    .me__title {
font-weight: 500;
font-size: 32px;
line-height: 36px;
text-align: left;
margin-bottom: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/me/__title/me__title.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,iBAAiB;AACjB,cAAc;AACd,SAAS;AACT;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,gBAAgB;AAChB,mBAAmB;IACf;AACJ","sourcesContent":[".me__title {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 500;\nfont-size: 40px;\nline-height: 44px;\ntext-align: right;\ncolor: #8B8B8B;\nmargin: 0;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .me__title {\nfont-weight: 500;\nfont-size: 32px;\nline-height: 36px;\n    }\n}\n\n@media (max-width: 620px) {\n    .me__title {\nfont-weight: 500;\nfont-size: 32px;\nline-height: 36px;\ntext-align: left;\nmargin-bottom: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
