// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio__heading {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 500;
font-size: 96px;
line-height: 100px;
margin: 52px 0;
text-align: left;
}

@media (min-width:620px) and (max-width: 1100px) {
    .portfolio__heading {
font-weight: 500;
font-size: 64px;
line-height: 64px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/blocks/portfolio/__heading/portfolio__heading.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,kBAAkB;AAClB,cAAc;AACd,gBAAgB;AAChB;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ","sourcesContent":[".portfolio__heading {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 500;\nfont-size: 96px;\nline-height: 100px;\nmargin: 52px 0;\ntext-align: left;\n}\n\n@media (min-width:620px) and (max-width: 1100px) {\n    .portfolio__heading {\nfont-weight: 500;\nfont-size: 64px;\nline-height: 64px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
