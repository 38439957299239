// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__main-btn {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #050024;
border: none;
border-radius: 12px;
background-color: transparent;
display: flex;
align-items: center;
text-decoration: none;
padding: 0;
}


@media (max-width: 620px) {
    .header__main-btn {
font-weight: 400;
font-size: 14px;
line-height: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/blocks/header/__main-btn/header__main-btn.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,YAAY;AACZ,mBAAmB;AACnB,6BAA6B;AAC7B,aAAa;AACb,mBAAmB;AACnB,qBAAqB;AACrB,UAAU;AACV;;;AAGA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;EACf;AACF","sourcesContent":[".header__main-btn {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 16px;\nline-height: 20px;\ncolor: #050024;\nborder: none;\nborder-radius: 12px;\nbackground-color: transparent;\ndisplay: flex;\nalign-items: center;\ntext-decoration: none;\npadding: 0;\n}\n\n\n@media (max-width: 620px) {\n    .header__main-btn {\nfont-weight: 400;\nfont-size: 14px;\nline-height: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
