// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio__description {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #050024;
margin: 0;
}

@media (max-width:620px) {
    .portfolio__description {
font-weight: 300;
font-size: 14px;
line-height: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/portfolio/__description/portfolio__description.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,SAAS;AACT;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ","sourcesContent":[".portfolio__description {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 300;\nfont-size: 16px;\nline-height: 20px;\ncolor: #050024;\nmargin: 0;\n}\n\n@media (max-width:620px) {\n    .portfolio__description {\nfont-weight: 300;\nfont-size: 14px;\nline-height: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
