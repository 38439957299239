// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__circle {
    animation: change1 4s infinite; /* Animation name, duration, infinite loop */
    width: 100px;
    height: 100px;
    position: absolute; /* Position absolute */
    background: radial-gradient(50% 50% at 50% 50%, #97B8E8 0%, rgba(200, 200, 255, 0) 100%);
    filter: blur(40px);
    z-index: 0;
    border-radius: 50%; /* Ensure it’s a circle */
    transform-origin: center; /* Set the origin for scaling */
    top: 0;
    }
        
        @keyframes change {
            0% {
                 transform: scale(1); /* Starting size */
             }
           
             50% {
                 transform: scale(0.8); /* Mid-animation size */
        
             }
        
             100% {
                 transform: scale(1); /* Ending size */
             }
         }`, "",{"version":3,"sources":["webpack://./src/blocks/project/__circle/project__circle.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B,EAAE,4CAA4C;IAC5E,YAAY;IACZ,aAAa;IACb,kBAAkB,EAAE,sBAAsB;IAC1C,wFAAwF;IACxF,kBAAkB;IAClB,UAAU;IACV,kBAAkB,EAAE,yBAAyB;IAC7C,wBAAwB,EAAE,+BAA+B;IACzD,MAAM;IACN;;QAEI;YACI;iBACK,mBAAmB,EAAE,kBAAkB;aAC3C;;aAEA;iBACI,qBAAqB,EAAE,uBAAuB;;aAElD;;aAEA;iBACI,mBAAmB,EAAE,gBAAgB;aACzC;SACJ","sourcesContent":[".project__circle {\n    animation: change1 4s infinite; /* Animation name, duration, infinite loop */\n    width: 100px;\n    height: 100px;\n    position: absolute; /* Position absolute */\n    background: radial-gradient(50% 50% at 50% 50%, #97B8E8 0%, rgba(200, 200, 255, 0) 100%);\n    filter: blur(40px);\n    z-index: 0;\n    border-radius: 50%; /* Ensure it’s a circle */\n    transform-origin: center; /* Set the origin for scaling */\n    top: 0;\n    }\n        \n        @keyframes change {\n            0% {\n                 transform: scale(1); /* Starting size */\n             }\n           \n             50% {\n                 transform: scale(0.8); /* Mid-animation size */\n        \n             }\n        \n             100% {\n                 transform: scale(1); /* Ending size */\n             }\n         }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
