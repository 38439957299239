// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    width: 100%; 
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 1;
}

@media (max-width: 620px) {
    .header {
        flex-direction: column;
        align-items: flex-end;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/header/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;IAC9B,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI;QACI,sBAAsB;QACtB,qBAAqB;IACzB;AACJ","sourcesContent":[".header {\n    display: flex;\n    width: 100%; \n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-start;\n    position: relative;\n    z-index: 1;\n}\n\n@media (max-width: 620px) {\n    .header {\n        flex-direction: column;\n        align-items: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
