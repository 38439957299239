// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__two-high {
    display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 24px; 
        width: 100%; 
        row-gap: 0;
}

@media (min-width: 620px) and (max-width: 1100px) {
.project__two-hign {
display: flex;
flex-direction: row;
overflow-x: scroll;
} 
.project__two-high > * { 
width: 430px; 
margin-right: 24px; 
}

.project__two-high > :last-child {
margin-right: 0; /* Убираем отступ у последнего элемента */
}


}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__two-high/project__two-high.css"],"names":[],"mappings":"AAAA;IACI,aAAa;QACT,qCAAqC;QACrC,SAAS;QACT,WAAW;QACX,UAAU;AAClB;;AAEA;AACA;AACA,aAAa;AACb,mBAAmB;AACnB,kBAAkB;AAClB;AACA;AACA,YAAY;AACZ,kBAAkB;AAClB;;AAEA;AACA,eAAe,EAAE,yCAAyC;AAC1D;;;AAGA","sourcesContent":[".project__two-high {\n    display: grid;\n        grid-template-columns: repeat(2, 1fr); \n        gap: 24px; \n        width: 100%; \n        row-gap: 0;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n.project__two-hign {\ndisplay: flex;\nflex-direction: row;\noverflow-x: scroll;\n} \n.project__two-high > * { \nwidth: 430px; \nmargin-right: 24px; \n}\n\n.project__two-high > :last-child {\nmargin-right: 0; /* Убираем отступ у последнего элемента */\n}\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
