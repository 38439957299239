// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__mob {
    display: flex;
    margin-bottom: 24px;
    align-items: flex-end;
}

@media (min-width: 620px) {
    .footer__mob {
display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__mob/footer__mob.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI;AACJ,aAAa;IACT;AACJ","sourcesContent":[".footer__mob {\n    display: flex;\n    margin-bottom: 24px;\n    align-items: flex-end;\n}\n\n@media (min-width: 620px) {\n    .footer__mob {\ndisplay: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
