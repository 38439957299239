// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__arrow-right {
    position: sticky;
    top: 50%;
    right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 2rem;
    transform: translateY(-50%);
    padding: 40px;
}

@media (max-width: 620px) {
    .project__arrow-right {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__arrow/project__arrow-right.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,QAAQ;IACR,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;IACf,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".project__arrow-right {\n    position: sticky;\n    top: 50%;\n    right: 20px;\n    background-color: transparent;\n    border: none;\n    color: white;\n    cursor: pointer;\n    font-size: 2rem;\n    transform: translateY(-50%);\n    padding: 40px;\n}\n\n@media (max-width: 620px) {\n    .project__arrow-right {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
