// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__circle-p {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

}`, "",{"version":3,"sources":["webpack://./src/blocks/me/__circle-p/me__circle-p.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,8BAA8B;;AAElC","sourcesContent":[".me__circle-p {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-between;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
