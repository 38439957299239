// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__heading {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 550;
font-size: 96px;
line-height: 100px;
color: #050024;
margin: 52px 0;
position: relative;
z-index: 1;
}

@media (min-width:620px) and (max-width: 1100px) {
    .project__heading {
font-weight: 550;
font-size: 64px;
line-height: 64px;
    }
}

@media (max-width:620px) {
    .project__heading {
        
font-weight: 550;
font-size: 40px;
line-height: 40px;

    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__heading/project__heading.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,kBAAkB;AAClB,cAAc;AACd,cAAc;AACd,kBAAkB;AAClB,UAAU;AACV;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ;;AAEA;IACI;;AAEJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;;IAEb;AACJ","sourcesContent":[".project__heading {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 550;\nfont-size: 96px;\nline-height: 100px;\ncolor: #050024;\nmargin: 52px 0;\nposition: relative;\nz-index: 1;\n}\n\n@media (min-width:620px) and (max-width: 1100px) {\n    .project__heading {\nfont-weight: 550;\nfont-size: 64px;\nline-height: 64px;\n    }\n}\n\n@media (max-width:620px) {\n    .project__heading {\n        \nfont-weight: 550;\nfont-size: 40px;\nline-height: 40px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
