// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__video-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 48px;
}

@media (max-width: 620px) {
    .footer__video-container {
        order: 2;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__video-container/footer__video-container.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;QACI,QAAQ;QACR,WAAW;IACf;AACJ","sourcesContent":[".footer__video-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin: 0 0 48px;\n}\n\n@media (max-width: 620px) {\n    .footer__video-container {\n        order: 2;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
