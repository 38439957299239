// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__mob-gif { 
        width: 60px;
        height: 60px;
        border-radius: 1000px;
    }
    
    
    @media (min-width: 620px) {
        .footer__mob-gif {
    display: none;
        }
    }
`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__mob-gif/footer__mob-gif.css"],"names":[],"mappings":"AAAA;QACQ,WAAW;QACX,YAAY;QACZ,qBAAqB;IACzB;;;IAGA;QACI;IACJ,aAAa;QACT;IACJ","sourcesContent":[".footer__mob-gif { \n        width: 60px;\n        height: 60px;\n        border-radius: 1000px;\n    }\n    \n    \n    @media (min-width: 620px) {\n        .footer__mob-gif {\n    display: none;\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
