// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.header__menu:last-child {
    margin-right: 0;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .header__menu {
flex-direction: column;
align-items: flex-end;
justify-content: flex-end;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/header/__menu/header__menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;AACJ,sBAAsB;AACtB,qBAAqB;AACrB,yBAAyB;IACrB;AACJ","sourcesContent":[".header__menu {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    align-items: center;\n    width: 100%;\n}\n\n.header__menu:last-child {\n    margin-right: 0;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .header__menu {\nflex-direction: column;\nalign-items: flex-end;\njustify-content: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
