// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__logo {
    font-family: 'HelveticaNeueCyr';
    font-style: normal;
    font-weight: 550;
    font-size: 96px;
    line-height: 96px;
    color: #050024;
    margin: 0;
    text-decoration: none;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .header__logo {
font-weight: 550;
font-size: 64px;
line-height: 64px;
    }
}

@media (max-width: 620px) {
    .header__logo {
font-weight: 550;
font-size: 40px;
line-height: 40px;
text-align: right;
margin-bottom: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/header/__logo/header__logo.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,iBAAiB;AACjB,mBAAmB;IACf;AACJ","sourcesContent":[".header__logo {\n    font-family: 'HelveticaNeueCyr';\n    font-style: normal;\n    font-weight: 550;\n    font-size: 96px;\n    line-height: 96px;\n    color: #050024;\n    margin: 0;\n    text-decoration: none;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .header__logo {\nfont-weight: 550;\nfont-size: 64px;\nline-height: 64px;\n    }\n}\n\n@media (max-width: 620px) {\n    .header__logo {\nfont-weight: 550;\nfont-size: 40px;\nline-height: 40px;\ntext-align: right;\nmargin-bottom: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
