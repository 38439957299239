// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .portfolio__img {
    width: 100%;
    height: auto;
    display: block;
    transition: opacity 0.3s ease;
    opacity: 1;
    position: relative;
    border-radius: 24px;
  }
`, "",{"version":3,"sources":["webpack://./src/blocks/portfolio/__img/portfolio__img.css"],"names":[],"mappings":";IACI;IACA,WAAW;IACX,YAAY;IACZ,cAAc;IACd,6BAA6B;IAC7B,UAAU;IACV,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":["\n    .portfolio__img {\n    width: 100%;\n    height: auto;\n    display: block;\n    transition: opacity 0.3s ease;\n    opacity: 1;\n    position: relative;\n    border-radius: 24px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
