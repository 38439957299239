// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__p-mobile {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 20px;
color: #050024;
margin: 0;
    }
    
    @media (min-width: 620px) {
        .me__p-mobile {
            display: none;
        }
    }`, "",{"version":3,"sources":["webpack://./src/blocks/me/__p-mobile/me__p-mobile.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,SAAS;IACL;;IAEA;QACI;YACI,aAAa;QACjB;IACJ","sourcesContent":[".me__p-mobile {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 300;\nfont-size: 14px;\nline-height: 20px;\ncolor: #050024;\nmargin: 0;\n    }\n    \n    @media (min-width: 620px) {\n        .me__p-mobile {\n            display: none;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
