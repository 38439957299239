// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__carousel {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    align-items: flex-start;
}

.project__carousel > * { 
    max-width: 430px; 
    margin-right: 24px; 
}

.project__carousel > :last-child {
    margin-right: 0; 
}
`, "",{"version":3,"sources":["webpack://./src/blocks/project/__carousel/project__carousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".project__carousel {\n    display: flex;\n    flex-direction: row;\n    overflow-x: scroll;\n    align-items: flex-start;\n}\n\n.project__carousel > * { \n    max-width: 430px; \n    margin-right: 24px; \n}\n\n.project__carousel > :last-child {\n    margin-right: 0; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
