// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footer__link {
    font-family: 'HelveticaNeueCyr';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #050024;
    margin-right: 60px;
    text-decoration: none;
    }
    
    .footer__link:last-of-type {
        margin-right: 0px;
    }

    @media (min-width: 620px) and (max-width: 1100px) {
        .footer__link {
    margin-bottom: 12px;
    margin-right: 0;
        }
        .footer__link:last-of-type {
            margin-bottom: 0;
        }
    }


    @media (max-width: 620px) {
        .footer__link {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
margin-right: 24px;
        }
        .footer__link:last-of-type {
            margin-right: 0;
        }
    }

    .footer__link:hover {
        opacity: 0.7;
    }`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__link/footer__link.css"],"names":[],"mappings":";AACA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,qBAAqB;IACrB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI;IACJ,mBAAmB;IACnB,eAAe;QACX;QACA;YACI,gBAAgB;QACpB;IACJ;;;IAGA;QACI;AACR,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,kBAAkB;QACV;QACA;YACI,eAAe;QACnB;IACJ;;IAEA;QACI,YAAY;IAChB","sourcesContent":["\n.footer__link {\n    font-family: 'HelveticaNeueCyr';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 20px;\n    color: #050024;\n    margin-right: 60px;\n    text-decoration: none;\n    }\n    \n    .footer__link:last-of-type {\n        margin-right: 0px;\n    }\n\n    @media (min-width: 620px) and (max-width: 1100px) {\n        .footer__link {\n    margin-bottom: 12px;\n    margin-right: 0;\n        }\n        .footer__link:last-of-type {\n            margin-bottom: 0;\n        }\n    }\n\n\n    @media (max-width: 620px) {\n        .footer__link {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 14px;\nline-height: 20px;\nmargin-right: 24px;\n        }\n        .footer__link:last-of-type {\n            margin-right: 0;\n        }\n    }\n\n    .footer__link:hover {\n        opacity: 0.7;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
