// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.project__description {
    margin-bottom: 120px;
    list-style: none; 
}

@media (max-width: 620px) {
    .project__description {
        margin-bottom: 80px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__description/project__description.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":["\n.project__description {\n    margin-bottom: 120px;\n    list-style: none; \n}\n\n@media (max-width: 620px) {\n    .project__description {\n        margin-bottom: 80px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
