// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__circle {
    animation: change1 4s infinite; /* Animation name, duration, infinite loop */
    width: 100px;
    height: 100px;
    position: absolute; /* Position absolute */
    background: radial-gradient(50% 50% at 50% 50%, #97B8E8 0%, rgba(200, 200, 255, 0) 100%);
    filter: blur(40px);
    z-index: 0;
    border-radius: 50%; /* Ensure it’s a circle */
    transform-origin: center; /* Set the origin for scaling */
}

@keyframes change1 {
    0% {
        transform: scale(1.5); /* Starting size */
    }
    
    50% {
        transform: scale(4); /* Mid-animation size */
    }

    100% {
        transform: scale(1.5); /* Ending size */
    }
}
`, "",{"version":3,"sources":["webpack://./src/blocks/me/__circle/me__circle.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B,EAAE,4CAA4C;IAC5E,YAAY;IACZ,aAAa;IACb,kBAAkB,EAAE,sBAAsB;IAC1C,wFAAwF;IACxF,kBAAkB;IAClB,UAAU;IACV,kBAAkB,EAAE,yBAAyB;IAC7C,wBAAwB,EAAE,+BAA+B;AAC7D;;AAEA;IACI;QACI,qBAAqB,EAAE,kBAAkB;IAC7C;;IAEA;QACI,mBAAmB,EAAE,uBAAuB;IAChD;;IAEA;QACI,qBAAqB,EAAE,gBAAgB;IAC3C;AACJ","sourcesContent":[".me__circle {\n    animation: change1 4s infinite; /* Animation name, duration, infinite loop */\n    width: 100px;\n    height: 100px;\n    position: absolute; /* Position absolute */\n    background: radial-gradient(50% 50% at 50% 50%, #97B8E8 0%, rgba(200, 200, 255, 0) 100%);\n    filter: blur(40px);\n    z-index: 0;\n    border-radius: 50%; /* Ensure it’s a circle */\n    transform-origin: center; /* Set the origin for scaling */\n}\n\n@keyframes change1 {\n    0% {\n        transform: scale(1.5); /* Starting size */\n    }\n    \n    50% {\n        transform: scale(4); /* Mid-animation size */\n    }\n\n    100% {\n        transform: scale(1.5); /* Ending size */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
