// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__video {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 1000px;
    z-index: 1;
    position: absolute; /* Position absolute */
    transition: transform 0.3s ease;
}

.me__video.enlarged {
    transform: translateX(25%) scale(1.5);
}

@media (max-width: 620px) {
    .me__video.enlarged {
        transform: translateY(-25%) translateX(25%) scale(1.5);
    }
}
`, "",{"version":3,"sources":["webpack://./src/blocks/me/__video/me__video.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,UAAU;IACV,kBAAkB,EAAE,sBAAsB;IAC1C,+BAA+B;AACnC;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI;QACI,sDAAsD;IAC1D;AACJ","sourcesContent":[".me__video {\n    width: 100px;\n    height: 100px;\n    background-color: #fff;\n    border-radius: 1000px;\n    z-index: 1;\n    position: absolute; /* Position absolute */\n    transition: transform 0.3s ease;\n}\n\n.me__video.enlarged {\n    transform: translateX(25%) scale(1.5);\n}\n\n@media (max-width: 620px) {\n    .me__video.enlarged {\n        transform: translateY(-25%) translateX(25%) scale(1.5);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
