// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 260px; 
}

@media (max-width: 620px) {
    .me__container {
flex-direction: column;
align-items: flex-start;
height: 360px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/me/__container/me__container.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI;AACJ,sBAAsB;AACtB,uBAAuB;AACvB,aAAa;IACT;AACJ","sourcesContent":[".me__container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-end;\n    width: 100%;\n    height: 260px; \n}\n\n@media (max-width: 620px) {\n    .me__container {\nflex-direction: column;\nalign-items: flex-start;\nheight: 360px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
