// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__p {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
text-align: right;
color: #050024;
margin: 0;
width: max-content;
}

@media (max-width: 620px) {
    .footer__p {
text-align: left;
font-weight: 300;
font-size: 14px;
line-height: 20px;
order: 1;
display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__p/footer__p.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,iBAAiB;AACjB,cAAc;AACd,SAAS;AACT,kBAAkB;AAClB;;AAEA;IACI;AACJ,gBAAgB;AAChB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,QAAQ;AACR,aAAa;IACT;AACJ","sourcesContent":[".footer__p {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 300;\nfont-size: 16px;\nline-height: 20px;\ntext-align: right;\ncolor: #050024;\nmargin: 0;\nwidth: max-content;\n}\n\n@media (max-width: 620px) {\n    .footer__p {\ntext-align: left;\nfont-weight: 300;\nfont-size: 14px;\nline-height: 20px;\norder: 1;\ndisplay: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
