// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
    font-family: "Inter", Arial, sans-serif;
    margin: 0 auto;
    max-width: 1336px;
    padding: 0 52px;
}

@media (min-width: 620px) and (max-width: 1100px) {
        .body {
           padding: 0 32px;
        }
    }

@media (max-width: 620px) {
    .body {
        padding: 0 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/body/body.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;;AAEA;QACQ;WACG,eAAe;QAClB;IACJ;;AAEJ;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".body {\n    font-family: \"Inter\", Arial, sans-serif;\n    margin: 0 auto;\n    max-width: 1336px;\n    padding: 0 52px;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n        .body {\n           padding: 0 32px;\n        }\n    }\n\n@media (max-width: 620px) {\n    .body {\n        padding: 0 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
