// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__link {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #050024;
margin-left: 60px;
text-decoration: none;
padding: 0;
background-color: transparent;
border: none;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .header__link {
margin: 0 0 12px 0;
    }
}

@media (max-width: 620px) {
    .header__link {
font-weight: 400;
font-size: 14px;
line-height: 20px;
margin-left: 24px;
  }
}

.header__link:hover {
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/blocks/header/__link/header__link.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,iBAAiB;AACjB,qBAAqB;AACrB,UAAU;AACV,6BAA6B;AAC7B,YAAY;AACZ;;AAEA;IACI;AACJ,kBAAkB;IACd;AACJ;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,iBAAiB;EACf;AACF;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header__link {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 16px;\nline-height: 20px;\ncolor: #050024;\nmargin-left: 60px;\ntext-decoration: none;\npadding: 0;\nbackground-color: transparent;\nborder: none;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .header__link {\nmargin: 0 0 12px 0;\n    }\n}\n\n@media (max-width: 620px) {\n    .header__link {\nfont-weight: 400;\nfont-size: 14px;\nline-height: 20px;\nmargin-left: 24px;\n  }\n}\n\n.header__link:hover {\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
