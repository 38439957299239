// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__text {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 44px;
color: #8B8B8B;
max-width: 1000px;
margin: 0;
}


@media (min-width:620px) and (max-width: 1100px) {
    .project__text {
font-weight: 400;
font-size: 32px;
line-height: 36px;
    }
}

@media (max-width:620px) {
    .project__text {
font-weight: 400;
font-size: 32px;
line-height: 36px;

    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__text/project__text.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,iBAAiB;AACjB,SAAS;AACT;;;AAGA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;;IAEb;AACJ","sourcesContent":[".project__text {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 40px;\nline-height: 44px;\ncolor: #8B8B8B;\nmax-width: 1000px;\nmargin: 0;\n}\n\n\n@media (min-width:620px) and (max-width: 1100px) {\n    .project__text {\nfont-weight: 400;\nfont-size: 32px;\nline-height: 36px;\n    }\n}\n\n@media (max-width:620px) {\n    .project__text {\nfont-weight: 400;\nfont-size: 32px;\nline-height: 36px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
