// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__circle {
animation: change 6s infinite; 
position: absolute;
width: 1454px;
height: 1458px;
left: calc(50% - 1454px/2 + 310px);
top: -39px;
background: radial-gradient(50% 50% at 50% 50%, #97B8E8 0%, rgba(200, 200, 255, 0) 100%);
filter: blur(60.4167px);
z-index: 0;
}
    
    @keyframes change {
        0% {
             transform: scale(1); /* Starting size */
         }
       
         50% {
             transform: scale(0.8); /* Mid-animation size */
    
         }
    
         100% {
             transform: scale(1); /* Ending size */
         }
     }`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__circle/footer__circle.css"],"names":[],"mappings":"AAAA;AACA,6BAA6B;AAC7B,kBAAkB;AAClB,aAAa;AACb,cAAc;AACd,kCAAkC;AAClC,UAAU;AACV,wFAAwF;AACxF,uBAAuB;AACvB,UAAU;AACV;;IAEI;QACI;aACK,mBAAmB,EAAE,kBAAkB;SAC3C;;SAEA;aACI,qBAAqB,EAAE,uBAAuB;;SAElD;;SAEA;aACI,mBAAmB,EAAE,gBAAgB;SACzC;KACJ","sourcesContent":[".footer__circle {\nanimation: change 6s infinite; \nposition: absolute;\nwidth: 1454px;\nheight: 1458px;\nleft: calc(50% - 1454px/2 + 310px);\ntop: -39px;\nbackground: radial-gradient(50% 50% at 50% 50%, #97B8E8 0%, rgba(200, 200, 255, 0) 100%);\nfilter: blur(60.4167px);\nz-index: 0;\n}\n    \n    @keyframes change {\n        0% {\n             transform: scale(1); /* Starting size */\n         }\n       \n         50% {\n             transform: scale(0.8); /* Mid-animation size */\n    \n         }\n    \n         100% {\n             transform: scale(1); /* Ending size */\n         }\n     }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
