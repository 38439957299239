// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: -webkit-fill-available;
    justify-content: center;
    align-items: flex-start;
background: rgba(5, 0, 36, 0.1);
backdrop-filter: blur(15px);
overflow-y: scroll;
padding: 50px 0 50px;
}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__modal/project__modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,aAAa;IACb,OAAO;IACP,MAAM;IACN,WAAW;IACX,8BAA8B;IAC9B,uBAAuB;IACvB,uBAAuB;AAC3B,+BAA+B;AAC/B,2BAA2B;AAC3B,kBAAkB;AAClB,oBAAoB;AACpB","sourcesContent":[".project__modal {\n    display: flex;\n    position: fixed;\n    z-index: 1000;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: -webkit-fill-available;\n    justify-content: center;\n    align-items: flex-start;\nbackground: rgba(5, 0, 36, 0.1);\nbackdrop-filter: blur(15px);\noverflow-y: scroll;\npadding: 50px 0 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
