// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__p {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #050024;
position: relative;
margin: 0;
}

@media (max-width: 620px) {
    .me__p {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/blocks/me/__p/me__p.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,kBAAkB;AAClB,SAAS;AACT;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".me__p {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 300;\nfont-size: 16px;\nline-height: 20px;\ncolor: #050024;\nposition: relative;\nmargin: 0;\n}\n\n@media (max-width: 620px) {\n    .me__p {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
