// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio__card {
        border: none; 
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease;
        cursor: pointer;
    }

   `, "",{"version":3,"sources":["webpack://./src/blocks/portfolio/__card/portfolio__card.css"],"names":[],"mappings":"AAAA;QACQ,YAAY;QACZ,kBAAkB;QAClB,gBAAgB;QAChB,+BAA+B;QAC/B,eAAe;IACnB","sourcesContent":[".portfolio__card {\n        border: none; \n        position: relative;\n        overflow: hidden;\n        transition: transform 0.3s ease;\n        cursor: pointer;\n    }\n\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
