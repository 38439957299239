// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__animation {
    position: relative;
    width: 100px;
    height: 100px;
    z-index: 0;
    margin: auto 0;
    transition: transform 0.3s ease;
}

@media (max-width:620px) {
    .me__animation {
        margin-bottom: 20px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/blocks/me/__animation/me__animation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,UAAU;IACV,cAAc;IACd,+BAA+B;AACnC;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".me__animation {\n    position: relative;\n    width: 100px;\n    height: 100px;\n    z-index: 0;\n    margin: auto 0;\n    transition: transform 0.3s ease;\n}\n\n@media (max-width:620px) {\n    .me__animation {\n        margin-bottom: 20px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
