// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__title {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #050024;
max-width: 1000px;
margin: 0 0 8px;
}

@media (max-width:620px) {
    .project__title {

font-weight: 300;
font-size: 14px;
line-height: 20px;
margin-bottom: 4px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/project/__title/project__title.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,iBAAiB;AACjB,eAAe;AACf;;AAEA;IACI;;AAEJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,kBAAkB;IACd;AACJ","sourcesContent":[".project__title {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 300;\nfont-size: 16px;\nline-height: 20px;\ncolor: #050024;\nmax-width: 1000px;\nmargin: 0 0 8px;\n}\n\n@media (max-width:620px) {\n    .project__title {\n\nfont-weight: 300;\nfont-size: 14px;\nline-height: 20px;\nmargin-bottom: 4px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
