// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio__name {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 44x;
color: #8B8B8B;
margin: 16px 0 4px;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .portfolio__name {
font-weight: 500;
font-size: 32px;
line-height: 36px;
    }
}

@media (max-width: 620px) {
    .portfolio__name {
font-weight: 500;
font-size: 32px;
line-height: 36px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/blocks/portfolio/__name/portfolio__name.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd,kBAAkB;AAClB;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ","sourcesContent":[".portfolio__name {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 500;\nfont-size: 40px;\nline-height: 44x;\ncolor: #8B8B8B;\nmargin: 16px 0 4px;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .portfolio__name {\nfont-weight: 500;\nfont-size: 32px;\nline-height: 36px;\n    }\n}\n\n@media (max-width: 620px) {\n    .portfolio__name {\nfont-weight: 500;\nfont-size: 32px;\nline-height: 36px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
