// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__heading {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 550;
font-size: 96px;
line-height: 96px;
color: #050024;
margin: 0;
}


@media (min-width: 620px) and (max-width: 1100px) {
    .me__heading {
font-weight: 550;
font-size: 64px;
line-height: 64px;
    }
}

@media (max-width: 620px) { 
    .me__heading {
display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/me/__heading/me__heading.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,SAAS;AACT;;;AAGA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;IACb;AACJ;;AAEA;IACI;AACJ,aAAa;IACT;AACJ","sourcesContent":[".me__heading {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 550;\nfont-size: 96px;\nline-height: 96px;\ncolor: #050024;\nmargin: 0;\n}\n\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .me__heading {\nfont-weight: 550;\nfont-size: 64px;\nline-height: 64px;\n    }\n}\n\n@media (max-width: 620px) { \n    .me__heading {\ndisplay: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
