// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio__grid {
    display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 24px; 
        width: 100%; 
        row-gap: 32px;
}

@media (max-width: 720px) {
    .portfolio__grid {
       grid-template-columns: repeat(1, 1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/portfolio/__grid/portfolio__grid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;QACT,qCAAqC;QACrC,SAAS;QACT,WAAW;QACX,aAAa;AACrB;;AAEA;IACI;OACG,qCAAqC;IACxC;AACJ","sourcesContent":[".portfolio__grid {\n    display: grid;\n        grid-template-columns: repeat(2, 1fr); \n        gap: 24px; \n        width: 100%; \n        row-gap: 32px;\n}\n\n@media (max-width: 720px) {\n    .portfolio__grid {\n       grid-template-columns: repeat(1, 1fr);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
