// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__title {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 44px;
color: #8B8B8B;
margin: 0 0 48px;
max-width: 564px;
}

@media (min-width: 620px) and (max-width: 1100px) {
    .footer__title {
font-weight: 500;
font-size: 32px;
line-height: 36px;
max-width: 286px;
    }
}

@media (max-width: 620px) {
    .footer__title {
font-weight: 500;
font-size: 32px;
line-height: 36px;
max-width: 286px;
margin-bottom: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__title/footer__title.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,gBAAgB;AAChB,gBAAgB;AAChB;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,gBAAgB;IACZ;AACJ;;AAEA;IACI;AACJ,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,gBAAgB;AAChB,mBAAmB;IACf;AACJ","sourcesContent":[".footer__title {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 500;\nfont-size: 40px;\nline-height: 44px;\ncolor: #8B8B8B;\nmargin: 0 0 48px;\nmax-width: 564px;\n}\n\n@media (min-width: 620px) and (max-width: 1100px) {\n    .footer__title {\nfont-weight: 500;\nfont-size: 32px;\nline-height: 36px;\nmax-width: 286px;\n    }\n}\n\n@media (max-width: 620px) {\n    .footer__title {\nfont-weight: 500;\nfont-size: 32px;\nline-height: 36px;\nmax-width: 286px;\nmargin-bottom: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
