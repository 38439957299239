// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__button {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
margin-right: 16px;
background-color: #050024;
padding: 32px 80px;
border-radius: 20px;
border: none;
text-decoration: none;
}

@media (min-width: 630px) and (max-width: 1100px) {
    .footer__button {
padding: 20px 40px;
    }
}

@media (max-width: 630px) {
    .footer__button {
padding: 20px 40px;
width: 100%;
margin-right: 0;
text-align: center;
    }
}

.footer__button:hover {
    opacity: 0.9;
}
`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__button/footer__button.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,cAAc;AACd,kBAAkB;AAClB,yBAAyB;AACzB,kBAAkB;AAClB,mBAAmB;AACnB,YAAY;AACZ,qBAAqB;AACrB;;AAEA;IACI;AACJ,kBAAkB;IACd;AACJ;;AAEA;IACI;AACJ,kBAAkB;AAClB,WAAW;AACX,eAAe;AACf,kBAAkB;IACd;AACJ;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".footer__button {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 700;\nfont-size: 16px;\nline-height: 20px;\ncolor: #FFFFFF;\nmargin-right: 16px;\nbackground-color: #050024;\npadding: 32px 80px;\nborder-radius: 20px;\nborder: none;\ntext-decoration: none;\n}\n\n@media (min-width: 630px) and (max-width: 1100px) {\n    .footer__button {\npadding: 20px 40px;\n    }\n}\n\n@media (max-width: 630px) {\n    .footer__button {\npadding: 20px 40px;\nwidth: 100%;\nmargin-right: 0;\ntext-align: center;\n    }\n}\n\n.footer__button:hover {\n    opacity: 0.9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
