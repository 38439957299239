// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__heading {
font-family: 'HelveticaNeueCyr';
font-style: normal;
font-weight: 550;
font-size: 96px;
line-height: 100px;
color: #050024;
margin: 0 0 52px;
}`, "",{"version":3,"sources":["webpack://./src/blocks/footer/__heading/footer__heading.css"],"names":[],"mappings":"AAAA;AACA,+BAA+B;AAC/B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,kBAAkB;AAClB,cAAc;AACd,gBAAgB;AAChB","sourcesContent":[".footer__heading {\nfont-family: 'HelveticaNeueCyr';\nfont-style: normal;\nfont-weight: 550;\nfont-size: 96px;\nline-height: 100px;\ncolor: #050024;\nmargin: 0 0 52px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
