// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me__content {
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 424px;
    margin-top: 80px;
}`, "",{"version":3,"sources":["webpack://./src/blocks/me/__content/me__content.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".me__content {\n    width: 100%; \n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    align-items: center;\n    height: 424px;\n    margin-top: 80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
